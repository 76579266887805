import React from 'react'
import { graphql, Link } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import Pagination from '../components/pagination'
import ArticlePreview from '../components/article-preview'
import 'bootstrap/dist/css/bootstrap.min.css';

class BlogIndex extends React.Component {


    render() {
        const siteTitle = get(this, 'props.data.site.siteMetadata.title')
        const posts = get(this, 'props.data.allContentfulNfnPost.edges')

        return (
            <Layout location={this.props.location}>
                <div style={{ background: '#fff' }}>
                    <Helmet title={siteTitle} />
                    <div className="wrapper">
                        <h2 className="section-headline">Recent Articles</h2>
                        <div className="home-posts">

                            <Pagination pageContext={this.props.pageContext} />

                            <ul>
                                {posts.map(({ node }) => {
                                    return (
                                        <li key={node.slug} className="pb-5">
                                            <ArticlePreview article={node} />
                                        </li>
                                    )
                                })}
                            </ul>

                            <Pagination pageContext={this.props.pageContext} />
                        </div>

                    </div>
                </div>
            </Layout>
        )
    }
}

export default BlogIndex

export const pageQuery = graphql`
  query BlogListQuery($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulNfnPost(
        sort: { fields: [createdLocal], order: DESC }
        limit: $limit
        skip: $skip
        ) {
      edges {
        node {
          postTitle
          slug
          publishDate
          createdLocal
          feedName
          postSummary {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
